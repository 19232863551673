import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`DB Stiff Leg Deadlifts 4-4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`10-Pistols (5/leg)`}</p>
    <p>{`15-Burpees`}</p>
    <p>{`20-Jump Squats`}</p>
    <p>{`25-Double Unders`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids today.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      